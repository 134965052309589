<!--平台补贴分摊统计-->
<template>
  <div class="SubsidyCount">


    <el-row :gutter="10" style="margin-bottom: 20px;">
      <el-col :span="8">
        <SelectedMS @reload="update" ></SelectedMS>
      </el-col>
      <!-- <el-col :span="4">
        <el-select v-model="tableParams.merchantId" placeholder="请选择门店" @change="onMerchantSel" clearable class="mr15">
          <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
            :value="item.merchantId">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="tableParams.shopId" placeholder="请选择店铺" @change="onShopSel" clearable class="mr15">
          <el-option v-for="item in tableShopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
          </el-option>
        </el-select>
      </el-col> -->
      <el-col :span="8">
        <el-date-picker class="mr20" v-model="tableParams.queryMonth" type="month" placeholder="选择月"
          :pickerOptions="pickerOptions" @change="goChangeMonth">
        </el-date-picker>
      </el-col>
      <el-col :span="1" :offset="5">
        <el-button type="primary" @click="goClick(1)">查看月补贴详情</el-button>
      </el-col>
    </el-row>




    <div>
      <span style="margin-right: 100px;">出账状态：{{ outBillStatus ? "已出账" : "待出账" }}</span>
      <span>门店月总付出成本：{{ totalAmount }}</span>
    </div>
    <div class="box">
      <el-empty description="暂无记录" class="empty" v-if="tableData.length==0||tableData==null"></el-empty>
      <div v-else class="box_item" v-for="(item, index) in tableData" :key="index">
        <div class="box_item_title">分摊方：{{ item.brandMerchantName }}</div>
        <div class="box_item_num">月补贴总额：{{ '￥' + item.totalAmount }}</div>
      </div>
    </div>
    <!--查看详情-->
    <el-dialog :visible.sync="editShow" title="查看详情" :close-on-click-modal="false" width="70%"
      custom-class="add-dialog">
      <section class="table-search">
        <el-button type="primary" :disabled="itemTableData && itemTableData.length == 0"
          @click="goClick(2)">导出</el-button>
      </section>
      <el-table :data="itemTableData" border stripe :header-cell-style="{
      background: '#FAFAFA',
      color: '#000000',
    }">
        <el-table-column label="交易时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.addDt }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.nickName }} | {{ scope.row.mobile }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单类型" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.businessTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="订单内容" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.subject }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分摊内容" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.description }}</span>
          </template>
        </el-table-column>
        <el-table-column label="本金折扣分摊金额" align="center">
          <!-- shareStatus 2 收款 4退款 -->
          <template slot-scope="scope">
            <span :class="scope.row.shareStatus==4?'red':''">{{ '￥' + scope.row.balanceShareAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="赠金折扣分摊金额" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.shareStatus==4?'red':''">{{ '￥' + scope.row.giveBalanceShareAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="卡券分摊金额" align="center">
          <template slot-scope="scope">
            <span :class="scope.row.shareStatus==4?'red':''">{{ '￥' + scope.row.cardShareAmount }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-footer">
        <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="itemTotal"
          :page-size="tableParams.pageSize" @size-change="onItemSizeChange" :page-sizes="[10, 20, 50, 100]"
          :current-page.sync="itemCurrentPage" @current-change="onItemCurrentPage">
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { getIdShopList } from "@/api/commercial/shop";
import SelectedMS from '@/views/vip/components/selecteMS'



import {
  getSubsidyPage,
  getSubsidyDetail,
  getSubsidyDetailToExcel,
} from "@/api/finances/subsidy.js";
import { formatterDate } from "@/utils/utils.js";
export default {
  name: "SubsidyCount",
  components:{
    SelectedMS
  },
  data() {
    return {
      formatterDate,
      commercialList: [], // 商户列表
      tableShopList: [], //店铺列表
      // 列表
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 10,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        queryMonth: new Date(), // 选择月份
      },
      editShow: false,
      itemTableData: [],
      itemParams: {
        page: 1,
        pageSize: 10,
      },
      itemCurrentPage: 1, // 当前页
      itemTotal: 0, // 总条数
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      itemShopId: "",
      totalAmount: 0,
      outBillStatus: false//出账状态
    };
  },
  mounted() {
    this.getAutoCommercialList(); // 【请求】商户下拉列表
    this.initMonth();
  },
  methods: {
    update(e){
      this.tableParams.merchantId = e.merchantId;
      this.tableParams.shopId = e.shopId;
      this.reload()
    },
    // 初始化月份
    initMonth() {
      let date = new Date();

      date.setDate(0);
      let y = date.getFullYear(); //获取年份

      let m = date.getMonth() + 1; //获取月份
      m = m > 10 ? m : "0" + m;
      this.tableParams.queryMonth = [y, m].join("-");
    },
    goChangeMonth() {
      this.tableParams.queryMonth = this.formatterDate(
        this.tableParams.queryMonth,
        4
      );
      this.getSubsidyPage();
    },
    // 余额账单明细导出
    getSubsidyDetailToExcel() {
    
      let name = this.tableParams.queryMonth + "余额账单明细";
      getSubsidyDetailToExcel(this.tableParams, name);
    },
    // 余额账单查询
    getSubsidyPage() {
      let params = {
        merchantId: this.tableParams.merchantId,
        shopId: this.tableParams.shopId,
        queryMonth: this.tableParams.queryMonth,
      };
      getSubsidyPage(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list||[];
          this.outBillStatus = res.data.outBillStatus;
          this.totalAmount = res.data.totalAmount;
        }
      });
    },
    // 余额账单详情
    getSubsidyDetail() {
      getSubsidyDetail(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.itemTableData = res.data.list;
          this.itemTotal = res.data.total;
          this.editShow = true;
        }
      });
    },
    // 点击
    goClick(val, shopId) {
      switch (val) {
        case 0:
          break;
        case 1:
          this.itemShopId = shopId;
          this.getSubsidyDetail();
          break;
        case 2:
          this.getSubsidyDetailToExcel();
          break;
      }
    },
    // 【请求】商户下拉列表
    getAutoCommercialList() {
      getAutoCommercialList().then((res) => {
        if (res.isSuccess == "yes") {
          this.commercialList = res.data;
          this.tableParams.merchantId=res.data[0].merchantId
        }
      });
    },

    // 【请求】店铺列表
    getIdShopList() {
      let data = {
        merchantId: this.tableParams.merchantId,
      };
      getIdShopList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableShopList = res.data;
        }
      });
    },
    // 【监听】筛选商户
    onMerchantSel() {
      if (this.tableParams.merchantId) {
        this.getIdShopList();
      } else {
        this.tableShopList = [];
      }
    },

    // 【监听】筛选店铺
    onShopSel() {
      this.reload();
    },
    // 【监听】表格重载
    reload() {
      this.getSubsidyPage();
    },
    // 【监听】表格分页点击
    onItemCurrentPage(page) {
      this.tableParams.page = page;
      this.getSubsidyDetail();
    },

    // 【监听】表格条数点击
    onItemSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.getSubsidyDetail();
    },
  },
};
</script>
<style lang="scss" scoped>
.red{
  color: red !important;
}
.SubsidyCount {
  padding: 0.15rem;
  box-sizing: border-box;
}

.box {
  width: 100%;
  height: 70vh;
  display: flex;
  margin-top: 20px;
  overflow: auto;
  .empty{
    margin: 0 auto;
  }
  .box_item {
    width: 200px;
    height: 100px;
    padding: 20px;
    border: 1px solid #999;
    margin-right: 20px;
    margin-bottom: 20px;

    .box_item_title {

      margin-bottom: 20px;
    }

  }
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>