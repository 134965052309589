import request from "@/utils/request";
import Export from "@/utils/export";
//分摊统计列表查询
export function getSubsidyPage(data) {
  return request("post", "/apm/statistic/share/statistics", data);
}

//分摊统计明细查询
export function getSubsidyDetail(data) {
  return request("post", "/apm/statistic/share/details", data);
}

//分摊统计明细导出
export function getSubsidyDetailToExcel(data,name) {
  return Export("post", "/apm/statistic/share/details/excelExport", data,name);
}
